import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"

const TreatmentSection = ({ intl, data }) => {
  return (
    <>
      <Section medium>
        <Columns vcentered centered>
          {/* <Column is5>
          <Img fluid={data.node.featuredImage.childImageSharp.fluid} />
        </Column> */}
          <Column is9>
            <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-1">
              {intl.formatMessage({
                id: `index.treatment.title`,
              })}
            </h2>
            <p className="is-size-5-desktop has-text-centered mb-2">
              {intl.formatMessage({
                id: `index.treatment.desc`,
              })}
            </p>
            <div className="has-text-centered">
              <Link
                className="button hvr-ripple-out is-normal is-size-7-mobile is-rounded is-uppercase is-warning has-text-weight-bold"
                to="/treatment"
              >
                {intl.formatMessage({
                  id: `button.more`,
                })}
              </Link>
            </div>
          </Column>
        </Columns>
      </Section>
      <Section medium white>
        <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-2">
          {intl.formatMessage({
            id: `treatment.title-1`,
          })}
        </h2>
        <Columns centered>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-1`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-5`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-6`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-7`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-2`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-5`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-3`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-3.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-3.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-3.item-3`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-4`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-4.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-4.item-2`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-6`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-6.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-6.item-2`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-7`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-7.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-7.item-2`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `treatment.desc-8`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-4`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(TreatmentSection)
