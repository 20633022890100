import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Hero, HeroBody } from "../CompoundComponents/Hero"
import StyledBackgroundSection from "../BackgroundSection"
import Button from "../Button"
import Icon from "../Icon"

const HeroIndex = ({ intl, location }) => {
  return (
    <>
      <StyledBackgroundSection>
        <Hero fh>
          <HeroBody>
            <Columns centered rowReverse vcentered>
              <Column is6 highlight>
                <h1 className="is-uppercase is-size-1 is-size-5-mobile has-text-centered has-text-weight-bold has-text-white">
                  {intl.formatMessage({ id: "index.hero.title" })}
                </h1>
                <p className="has-text-white is-size-3-desktop has-text-centered mb-1">
                  {intl.formatMessage({ id: "index.hero.desc" })}
                </p>
                <p className="buttons is-justify-content-center">
                  <Button aTag link="tel:+998953008885" warning ripple noBlank>
                    <Icon icon="headset" white />
                    <span className="has-text-weight-bold">
                      {intl.formatMessage({ id: "footer.col_1.phone" })}
                    </span>
                  </Button>
                  <Button aTag link="tel:+998945001096" warning ripple noBlank>
                    <Icon icon="headset" white />
                    <span className="has-text-weight-bold">
                      {intl.formatMessage({ id: "footer.col_1.phone2" })}
                    </span>
                  </Button>
                </p>
              </Column>
            </Columns>
          </HeroBody>
        </Hero>
      </StyledBackgroundSection>
    </>
  )
}

export default injectIntl(HeroIndex)
