import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"

const NewsSection = ({ intl }) => {
  return (
    <>
      <Section medium white noPaddingBottom>
        <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-1">
          {intl.formatMessage({
            id: `index.news.title`,
          })}
        </h2>
        <p className="is-size-5-desktop has-text-centered mb-3">
          {intl.formatMessage({
            id: `index.news.desc`,
          })}
        </p>
        <Columns centered>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `index.news.list.0`,
                })}
              </h3>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `index.news.list.1`,
                })}
              </h3>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `index.news.list.2`,
                })}
              </h3>
            </Box>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(NewsSection)
