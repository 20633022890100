import React from "react"
import classNames from "classnames"

export const CardImage = ({ icon, title }) => (
  <div className="card-image is-flex justify-content-center">
    <figure className="image is-flex is-96x96">
      <img src={icon} alt={title} />
    </figure>
  </div>
)

export const CardImg = ({ children }) => (
  <div className="card-image">
    <figure className="image is-3by2 card-img">{children}</figure>
  </div>
)

export const CardContent = ({ children }) => (
  <div className="card-content">{children}</div>
)

export const CardContentTitle = ({ children }) => (
  <p className="has-text-weight-bold has-text-centered">{children}</p>
)

export const CardContentSubtitle = ({ children, bottom }) => (
  <p
    className={classNames("is-size-6 has-text-centered", {
      "mb-1": bottom,
    })}
  >
    {children}
  </p>
)

export const CardContentPrice = ({ children }) => (
  <span class="tag is-success is-light mr-auto">{children}</span>
)

export const CardContentTags = ({ tags }) => {
  return (
    <div class="tags">
      {tags.map((item, index) => (
        <span key={index} class="tag is-light">
          {item}
        </span>
      ))}
    </div>
  )
}

export const Card = ({
  children,
  isShadowless,
  transparent,
  mb,
  mn,
  textCentered,
  primary,
}) => (
  <div
    className={classNames("card", {
      "is-shadowless": isShadowless,
      "has-text-centered": textCentered,
      "is-shadowless": isShadowless,
      "has-background-transparent": transparent,
      "mb-2": mb,
      "m-2": mn,
      "has-background-warning has-text-black": primary,
    })}
  >
    {children}
  </div>
)
