import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"

import Slick from "../Slick"

const PhysiotherapySection = ({ intl, data, minData }) => {
  return (
    <Section medium light>
      <Columns vcentered centered>
        <Column is8>
          <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-2">
            Физиотерапия
          </h2>
          <p className="is-size-5-desktop has-text-centered mb-2">
            {intl.formatMessage({
              id: `physiotherapy.desc`,
            })}
          </p>
          <div className="has-text-centered">
            <Link
              className="button hvr-ripple-out is-normal is-size-7-mobile is-uppercase is-warning is-rounded has-text-weight-bold is-normal has-family-pt-sans"
              to="/physiotherapy"
            >
              {intl.formatMessage({
                id: `button.more`,
              })}
            </Link>
          </div>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(PhysiotherapySection)
