import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Hero, HeroBody } from "../CompoundComponents/Hero"
import StyledBackgroundVegetables from "../BackgroundVegetables"
import Button from "../Button"

const HeroSectionVeg = ({ intl, location }) => {
  return (
    <>
      <StyledBackgroundVegetables>
        <Hero medium>
          <HeroBody>
            <Columns centered rowReverse vcentered>
              <Column is6 highlight>
                <h2 className="is-size-3 is-uppercase is-size-5-mobile has-text-white has-text-centered mb-1 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `vegetables.title`,
                  })}
                </h2>
                <p className="is-size-4-desktop has-text-white has-text-centered mb-2">
                  {intl.formatMessage({
                    id: `vegetables.sidebar.desc-1`,
                  })}
                </p>
                <div className="has-text-centered">
                  <Link
                    className="button hvr-ripple-out is-normal is-size-7-mobile is-rounded is-uppercase is-warning has-text-weight-bold"
                    to="/vegetables"
                  >
                    {intl.formatMessage({
                      id: `button.more`,
                    })}
                  </Link>
                </div>
              </Column>
            </Columns>
          </HeroBody>
        </Hero>
      </StyledBackgroundVegetables>
    </>
  )
}

export default injectIntl(HeroSectionVeg)
