import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import {
  Card,
  CardContent,
  CardContentTitle,
  CardImg,
} from "../CompoundComponents/Card"

const AboutSection = ({ intl, data }) => {
  return (
    <Section white medium>
      <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-3">
        {intl.formatMessage({ id: "index.about.title" })}
      </h2>
      <Columns centered vcentered>
        {data.edges.map(item => (
          <Column is3>
            <Link to={item.node.slug}>
              <Card>
                <CardImg>
                  <Img
                    fluid={item.node.featuredImage.childImageSharp.fluid}
                    alt={item.node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentTitle>
                    {intl.formatMessage({
                      id: `index.service-main.${item.node.id}.title`,
                    })}
                  </CardContentTitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
        ))}
      </Columns>
    </Section>
  )
}

export default injectIntl(AboutSection)
