import React, { Children } from "react"
import classNames from "classnames"

export const HeroBody = ({ children }) => (
  <div className="hero-body is-flex has-bg-000 align-items-center">
    <div className="container">{children}</div>
  </div>
)

export const HeroHead = ({ children }) => (
  <div className="hero-head">{Children.toArray(children)}</div>
)

export const Hero = ({ children, fh, primary, medium, border }) => {
  return (
    <section
      id="home"
      className={classNames("hero", {
        "is-fullheight-desktop": fh,
        "has-background-primary": primary,
        "is-medium": medium,
        "is-border-white": border,
      })}
    >
      {children}
    </section>
  )
}
