import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"

const DiagnosticsSection = ({ intl }) => {
  return (
    <>
      <Section medium white>
        <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-3">
          Диагностика
        </h2>
        <Columns centered>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `diagnostics.desc-1.title`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-5`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-6`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-7`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-8`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-9`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-10`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-1.list.item-11`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `diagnostics.desc-2.title-1`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-1.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-1.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-1.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-1.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-1.item-5`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-1.item-6`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `diagnostics.desc-2.title-1-2`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-2.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-2.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-2.item-3`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `diagnostics.desc-2.title-1-3`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-3.item-1`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box height noshadow>
              <h3 className="has-text-weight-bold is-size-5 has-text-centered">
                {intl.formatMessage({
                  id: `diagnostics.desc-2.title-1-4`,
                })}
              </h3>
              <div className="content">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `diagnostics.desc-2.list-4.item-1`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(DiagnosticsSection)
