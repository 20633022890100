import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Section } from "../CompoundComponents/Section"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Card, CardContent } from "../CompoundComponents/Card"
import Icon from "../Icon"

const AdvantagesSection = ({ intl }) => {
  return (
    <Section medium light>
      <Columns centered>
        <Column is9>
          <h2 className="has-text-centered is-uppercase is-size-4 is-size-5-mobile has-text-weight-bold mb-1">
            {intl.formatMessage({ id: "index.advantages.title" })}
          </h2>
          <p className="has-text-centered is-size-5-desktop mb-2">
            {intl.formatMessage({ id: "index.advantages.desc" })}
          </p>
        </Column>
        <Column is4>
          <Card textCentered grow isShadowless>
            <CardContent>
              <div className="mb-2 has-text-primary">
                <Icon icon="tree" size="3x" large />
              </div>
              <p className="has-text-weight-bold">
                {intl.formatMessage({ id: "index.advantages.list.0" })}
              </p>
            </CardContent>
          </Card>
        </Column>
        <Column is4>
          <Card textCentered grow isShadowless>
            <CardContent>
              <div className="mb-2 has-text-primary">
                <Icon icon="hand-holding-water" size="3x" large />
              </div>
              <p className="has-text-weight-bold">
                {intl.formatMessage({ id: "index.advantages.list.1" })}
              </p>
            </CardContent>
          </Card>
        </Column>
        <Column is4>
          <Card textCentered grow isShadowless>
            <CardContent>
              <div className="mb-2 has-text-primary">
                <Icon icon="user-nurse" size="3x" large />
              </div>
              <p className="has-text-weight-bold">
                {intl.formatMessage({ id: "index.advantages.list.2" })}
              </p>
            </CardContent>
          </Card>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(AdvantagesSection)
