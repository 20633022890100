import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import {
  Card,
  CardContent,
  CardContentTitle,
  CardContentSubtitle,
  CardImg,
} from "../CompoundComponents/Card"

import Slick from "../Slick"

const IndexPriceSection = ({ intl, dataCard }) => {
  return (
    <Section light medium>
      <Columns centered vcentered>
        <Column is8>
          <h2 className="is-size-3 is-size-5-mobile has-text-centered mb-1 has-text-weight-bold is-uppercase">
            {intl.formatMessage({ id: "header.nav.item-2" })}
          </h2>
          <p className="is-size-5-desktop has-text-centered">
            {intl.formatMessage({ id: "index.rooms.desc" })}
          </p>
        </Column>
        <Column centered is12>
          <Slick slideToShow={3} slideToScroll={1} speed={3000}>
            {dataCard.edges.map(item => (
              <Card isShadowless mn>
                <Link to={item.node.link}>
                  <CardImg>
                    <Img
                      fluid={item.node.featuredImage.childImageSharp.fluid}
                      alt={item.node.id}
                    />
                  </CardImg>
                </Link>

                <CardContent>
                  <CardContentTitle>
                    {intl.formatMessage({
                      id: `index.rooms.${item.node.id}.title`,
                    })}
                  </CardContentTitle>
                </CardContent>
                <footer className="card-footer">
                  <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                    {intl.formatMessage({
                      id: `index.rooms.${item.node.id}.room`,
                    })}
                  </span>
                  <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                    {intl.formatMessage({
                      id: `index.rooms.${item.node.id}.place`,
                    })}
                  </span>
                </footer>
                <footer className="card-footer has-text-centered">
                  <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                    {intl.formatMessage({
                      id: `index.rooms.${item.node.id}.price`,
                    })}
                  </span>
                </footer>

                <footer className="card-footer">
                  <Link
                    className="has-text-primary card-footer-item is-uppercase is-size-7 has-text-weight-bold"
                    to="/booking"
                  >
                    {intl.formatMessage({
                      id: `header.nav.item-5`,
                    })}
                  </Link>
                </footer>

                <footer className="card-footer"></footer>
              </Card>
            ))}
          </Slick>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(IndexPriceSection)
