import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import AboutSection from "../components/Sections/AboutSection"
import TreatmentSection from "../components/Sections/TreatmentSection"
import MineralWater from "../components/Sections/MineralWater"
import IndexPriceSection from "../components/Sections/IndexPriceSection"
import HeroIndex from "../components/Sections/HeroSection"
import HeroSectionVeg from "../components/Sections/HeroSectionVeg"
import AdvantagesSection from "../components/Sections/AdvantagesSection"
import DiagnosticsSection from "../components/Sections/DiagnosticsSection"
import PhysiotherapySection from "../components/Sections/PhysiotherapySection"
import Footer from "../components/Footer/index"
import NewsSection from "../components/Sections/NewsSection"

const IndexPage = ({ data, location }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи"
        description="Сўлим табиат. Шифобахш сув. Сифатли хизмат. Ниҳол саломатлик сиҳатгоҳи Тоғ тизмалари ён бағрида, Норин дарёси ўзанидаги Катта Фарғона канали билан туташ жойлашган."
      />
      <NavBar />
      <HeroIndex location={location} />
      <AdvantagesSection />
      <HeroSectionVeg />
      <IndexPriceSection data={data.section.edges[1]} dataCard={data.card} />
      <AboutSection data={data.service} />
      <TreatmentSection />
      <MineralWater />
      <DiagnosticsSection />
      <PhysiotherapySection />
      <NewsSection />
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const IndexPageQuery = graphql`
  query {
    mineral: allMineraldataYaml {
      edges {
        node {
          id
        }
      }
    }
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    service: allServiceYaml {
      edges {
        node {
          id
          slug
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    card: allRoomsYaml {
      edges {
        node {
          id
          link
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(IndexPage)
